import { global } from ":global";
import type { _Guarded } from ":shared/components/route";
import { platform$ } from ":src/modules/platform";

// TODO: check token only once across routes
// TODO: disable token checks on /auth but not /auth/login
// TODO: store non critical indicators in local storage to know this
export const checkPlatformSettings: _Guarded.AGuardStep = {
  runOn: ["app_init", "first_load"],
  loaderMsg: "loading platform settings...",
  async checker({ state, base, routeMatch, routeMatchBase, location }) {
    // return
    return platform$.apis.getPlatformSettings();
  },
};
